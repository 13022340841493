import * as fromRoot from "@app/store";
import { createSelector } from "@ngrx/store";
import { selectNutrientFilterState } from "../reducers";
import * as fromNutrientFilter from "../reducers/nutrient-filter.reducer";

export const selectActivePage = fromRoot.selectRouterParamData("page");

export const selectIsNutrientFilterLoading = () =>
  createSelector(
    selectNutrientFilterState(),
    fromNutrientFilter.selectIsLoading,
  );

export const selectNutrientFilterIds = () =>
  createSelector(
    selectNutrientFilterState(),
    fromNutrientFilter.selectNutrientFilterIds,
  );

export const selectNutrientFilterEntities = () =>
  createSelector(
    selectNutrientFilterState(),
    fromNutrientFilter.selectNutrientFilterEntities,
  );

export const selectAllNutrientFilters = () =>
  createSelector(
    selectNutrientFilterState(),
    fromNutrientFilter.selectAllNutrientFilters,
  );

export const selectNutrientFilterTotal = () =>
  createSelector(
    selectNutrientFilterState(),
    fromNutrientFilter.selectNutrientFilterTotal,
  );

export const selectCurrentNutrientFilterId = () =>
  createSelector(
    selectNutrientFilterState(),
    fromNutrientFilter.getSelectedNutrientFilterId,
  );

export const selectCurrentNutrientFilter = () =>
  createSelector(
    selectNutrientFilterEntities(),
    selectCurrentNutrientFilterId(),
    (nutrientFilterEntities, nutrientFilterId) =>
      nutrientFilterEntities[nutrientFilterId as string],
  );

export const selectNutrientFiltersNested = () =>
  createSelector(selectAllNutrientFilters(), (allNutrientFilters) => {
    const hideCodeList = ["vitamins", "minerals"];

    allNutrientFilters = allNutrientFilters.map((nutrient) => ({
      ...nutrient,
      isToggling: !hideCodeList.includes(nutrient.code),
    }));

    return [allNutrientFilters.slice(0, 4), allNutrientFilters.slice(4)];
  });

export const selectAppliedNutrientFilter = () =>
  createSelector(
    selectNutrientFilterState(),
    fromNutrientFilter.selectAppliedNutrientFilter,
  );

export const selectAppliedNutrientFilterIds = () =>
  createSelector(selectAppliedNutrientFilter(), (selectAppliedNutrientFilter) =>
    Object.keys(selectAppliedNutrientFilter),
  );

export const selectLastFilterType = () =>
  createSelector(selectNutrientFilterState(), (state) => state.lastFiltersType);
