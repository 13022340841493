import { Injectable } from "@angular/core";
import { FoodNutrientFilterDialogType } from "../../../shared/components/dialogs/food-nutrient-filter-dialog/food-nutrient-filter.enum";
import { DietarySupplementNutrientService } from "./dietary-supplement-nutrient.service";
import { BaseNutrientService } from "./interfaces/nutrient-service.interface";
import { ProductNutrientService } from "./product-nutrient.service";
import { RationDietarySupplementNutrientService } from "./ration-dietary-supplement-nutrient.service";
import { RationProductsNutrientService } from "./ration-products-nutrient.service";
import { RationRecipeNutrientService } from "./ration-recipe-nutrient.service";
import { RecipeCreateNutrientService } from "./recipe-create-nutrient.service";
import { RecipeNutrientService } from "./recipe-nutrient.service";

@Injectable({
  providedIn: "root",
})
export class NutrientServiceFactory {
  constructor(
    private dietarySupplementService: DietarySupplementNutrientService,
    private productService: ProductNutrientService,
    private recipeService: RecipeNutrientService,
    private rationDietarySupplementService: RationDietarySupplementNutrientService,
    private rationProductsService: RationProductsNutrientService,
    private rationRecipeService: RationRecipeNutrientService,
    private recipeCreateService: RecipeCreateNutrientService,
  ) {}

  createNutrientService(
    type: FoodNutrientFilterDialogType,
  ): BaseNutrientService {
    switch (type) {
      case FoodNutrientFilterDialogType.DIETARY_SUPPLEMENT:
        return this.dietarySupplementService;
      case FoodNutrientFilterDialogType.PRODUCT:
        return this.productService;
      case FoodNutrientFilterDialogType.RECIPE:
        return this.recipeService;
      case FoodNutrientFilterDialogType.RECIPE_CREATE:
        return this.recipeCreateService;
      case FoodNutrientFilterDialogType.RATION_DIETARY_SUPPLEMENT:
        return this.rationDietarySupplementService;
      case FoodNutrientFilterDialogType.RATION_PRODUCTS:
        return this.rationProductsService;
      case FoodNutrientFilterDialogType.RATION_RECIPE:
        return this.rationRecipeService;
      default:
        throw new Error(`Unsupported nutrient filter type: ${type}`);
    }
  }
}
