export enum FoodNutrientFilterDialogType {
  NONE = "none",
  DIETARY_SUPPLEMENT = "dietary_supplement",
  PRODUCT = "product",
  RECIPE = "recipe",
  RECIPE_CREATE = "recipe_create",
  RATION_DIETARY_SUPPLEMENT = "ration_dietary_supplement",
  RATION_PRODUCTS = "ration_products",
  RATION_RECIPE = "ration_recipe",
}
