import * as fromRoot from "@app/store";
import {
  Action,
  combineReducers,
  createFeatureSelector,
  createSelector,
} from "@ngrx/store";
import * as fromNutrientFilter from "./nutrient-filter.reducer";

export const nutrientFilterFeatureKey = "nutrient-filter";

export interface State extends fromRoot.State {
  [fromNutrientFilter.nutrientFilterFeatureKey]: fromNutrientFilter.State;
}

export function reducers(state: State | undefined, action: Action) {
  return combineReducers({
    [fromNutrientFilter.nutrientFilterFeatureKey]: fromNutrientFilter.reducer,
  })(state, action);
}

export const getNutrientFilterState = createFeatureSelector<State>(
  nutrientFilterFeatureKey,
);

export const selectNutrientFilterState = () =>
  createSelector(
    getNutrientFilterState,
    (state: State) => state[fromNutrientFilter.nutrientFilterFeatureKey],
  );
