import {
  applyNutrientFiltersDietarySupplement,
  applyNutrientFiltersProducts,
  applyNutrientFiltersRationDietarySupplement,
  applyNutrientFiltersRationProducts,
  applyNutrientFiltersRationRecipe,
  applyNutrientFiltersRecipe,
  applyNutrientFiltersRecipeCreate,
} from "@app/shared/stores/nutrient-filter/store";
import { FoodNutrientFilterDialogType as modalName } from "./food-nutrient-filter.enum";

export const APPLY_TABLE: Record<modalName, any | null> = {
  [modalName.NONE]: null,
  [modalName.DIETARY_SUPPLEMENT]: applyNutrientFiltersDietarySupplement,
  [modalName.PRODUCT]: applyNutrientFiltersProducts,
  [modalName.RECIPE]: applyNutrientFiltersRecipe,
  [modalName.RECIPE_CREATE]: applyNutrientFiltersRecipeCreate,
  [modalName.RATION_DIETARY_SUPPLEMENT]:
    applyNutrientFiltersRationDietarySupplement,
  [modalName.RATION_PRODUCTS]: applyNutrientFiltersRationProducts,
  [modalName.RATION_RECIPE]: applyNutrientFiltersRationRecipe,
};
