import { Injectable } from "@angular/core";
import { MonitoringService } from "@app/core/services/monitoring.service";
import { NutrientServiceFactory } from "@app/core/services/nutrient-filter";
import { APPLY_TABLE } from "@app/shared/components/dialogs/food-nutrient-filter-dialog/food-nutrient-filter.consts";
import { FoodNutrientFilterDialogType } from "@app/shared/components/dialogs/food-nutrient-filter-dialog/food-nutrient-filter.enum";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatLatestFrom } from "@ngrx/operators";
import { Store, select } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import * as fromNutrientFilter from "../../store";
import * as NutrientFilterActions from "../actions/nutrient-filter.actions";
import * as NutrientFilterSelectors from "../selectors/nutrient-filter.selectors";

@Injectable()
export class NutrientFilterEffects {
  loadNutrientFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NutrientFilterActions.loadNutrientFilters),
      concatLatestFrom(() => [
        this.store.pipe(
          select(NutrientFilterSelectors.selectAllNutrientFilters()),
        ),
        this.store.pipe(select(NutrientFilterSelectors.selectLastFilterType())),
      ]),
      switchMap(([action, allNutrientFilters, lastFilterType]) => {
        const modalName = action.modalName;
        const service =
          this.nutrientServiceFactory.createNutrientService(modalName);
        const reload = lastFilterType !== modalName;

        if (modalName !== FoodNutrientFilterDialogType.NONE) {
          this.monitoringService.sendEventToAplitude(
            `event_load_filter_${modalName.toLowerCase()}`,
          );
        }

        if (allNutrientFilters.length && !reload) {
          return of(
            NutrientFilterActions.loadNutrientFiltersSuccess({
              nutrientFilters: allNutrientFilters,
              lastFiltersType: modalName,
            }),
          );
        }

        return service.load().pipe(
          map((response) =>
            NutrientFilterActions.loadNutrientFiltersSuccess({
              nutrientFilters: response,
              lastFiltersType: modalName,
            }),
          ),
          catchError((error) =>
            of(NutrientFilterActions.loadNutrientFiltersFailure({ error })),
          ),
        );
      }),
    ),
  );

  applyNutrientFilters$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(NutrientFilterActions.applyNutrientFilters),
      concatLatestFrom(() => [
        this.store.pipe(select(NutrientFilterSelectors.selectActivePage)),
      ]),
      map(([action]): any => {
        const modalName: FoodNutrientFilterDialogType = action.modalName;

        const result = APPLY_TABLE[modalName];
        if (modalName === FoodNutrientFilterDialogType.NONE || !result) {
          return of(null);
        }

        this.monitoringService.sendEventToAplitude(
          `event_apply_filter_${modalName.toLowerCase()}`,
        );

        return result();
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<fromNutrientFilter.State>,
    private nutrientServiceFactory: NutrientServiceFactory,
    private monitoringService: MonitoringService,
  ) {}
}
