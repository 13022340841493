import { FoodNutrientFilterDialogType } from "@app/shared/components/dialogs/food-nutrient-filter-dialog/food-nutrient-filter.enum";
import { EntityAdapter, EntityState, createEntityAdapter } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import * as NutrientFilterActions from "../actions/nutrient-filter.actions";

export const nutrientFilterFeatureKey = "nutrient-filter";

export interface State extends EntityState<any> {
  selectedNutrientFilterId: string | null;
  isLoading: boolean;
  error: any;
  appliedNutrientFilter: object;
  lastFiltersType: FoodNutrientFilterDialogType;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({
  selectId: (nutrientFilter: any) => nutrientFilter.code,
  sortComparer: false,
});

export const initialState: State = adapter.getInitialState({
  selectedNutrientFilterId: null,
  isLoading: false,
  error: null,
  appliedNutrientFilter: {},
  lastFiltersType: FoodNutrientFilterDialogType.NONE,
});

export const reducer = createReducer(
  initialState,

  on(NutrientFilterActions.loadNutrientFilters, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(
    NutrientFilterActions.loadNutrientFiltersSuccess,
    (state: any, { nutrientFilters, lastFiltersType }) => ({
      ...adapter.setAll(
        [].concat(...nutrientFilters).map((nutrient: any) => ({
          ...nutrient,
          activeCode: state.appliedNutrientFilter[nutrient.code] || "",
          children: nutrient.children?.map((childNutrient: any) => ({
            ...childNutrient,
            activeCode: state.appliedNutrientFilter[childNutrient.code] || "",
          })),
        })),
        state,
      ),
      lastFiltersType,
      isLoading: false,
    }),
  ),
  on(NutrientFilterActions.loadNutrientFiltersFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(NutrientFilterActions.updateNutrientFilter, (state, { nutrientCode }) => ({
    ...adapter.map(
      (nutrient) => ({
        ...nutrient,
        activeCode: nutrient.code.includes(nutrientCode) ? nutrientCode : "",
        children: nutrient.children?.map((childNutrient: any) => ({
          ...childNutrient,
          activeCode: childNutrient.code.includes(nutrientCode)
            ? nutrientCode
            : "",
        })),
      }),
      state,
    ),
  })),

  on(NutrientFilterActions.applyNutrientFilters, (state) => ({
    ...state,
    appliedNutrientFilter: Object.values(state.entities)
      .flatMap((curr) => [curr, ...(curr.children || [])])
      .reduce(
        (acc, curr) =>
          curr.activeCode ? { ...acc, [curr.code]: curr.code } : acc,
        {},
      ),
  })),

  on(NutrientFilterActions.restoreAppliedNutrientFilters, (state: any) => ({
    ...adapter.map(
      (nutrient) => ({
        ...nutrient,
        activeCode: state.appliedNutrientFilter[nutrient.code] || "",
        children: nutrient.children?.map((childNutrient: any) => ({
          ...childNutrient,
          activeCode: state.appliedNutrientFilter[childNutrient.code] || "",
        })),
      }),
      state,
    ),
  })),

  on(
    NutrientFilterActions.resetNutrientFilters,
    NutrientFilterActions.resetAppliedNutrientFilters,
    (state) => ({
      ...adapter.map(
        (nutrient) => ({
          ...nutrient,
          activeCode: "",
          children: nutrient.children?.map((childNutrient: any) => ({
            ...childNutrient,
            activeCode: "",
          })),
        }),
        state,
      ),
    }),
  ),

  on(NutrientFilterActions.clearNutrientFilters, (state) => ({
    ...adapter.removeAll({ ...state, ...initialState }),
  })),
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();

export const selectNutrientFilterIds = selectIds;
export const selectNutrientFilterEntities = selectEntities;
export const selectAllNutrientFilters = selectAll;
export const selectNutrientFilterTotal = selectTotal;

export const getSelectedNutrientFilterId = (state: State) =>
  state.selectedNutrientFilterId;
export const selectIsLoading = (state: State) => state.isLoading;
export const selectAppliedNutrientFilter = (state: State) =>
  state.appliedNutrientFilter;
export const selectLastFilterType = (state: State) => state.lastFiltersType;
