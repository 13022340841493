import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { Observable } from "rxjs";
import { AbstractNutrientService } from "./abstract-nutrient.service";

@Injectable({
  providedIn: "root",
})
export class RationDietarySupplementNutrientService extends AbstractNutrientService {
  readonly http = inject(HttpClient);

  load(): Observable<any> {
    return this.http.get<any>(
      `${environment.primaryApiUrl}/dietary_supplement/filters`,
    );
  }
}
