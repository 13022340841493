import { FoodNutrientFilterDialogType } from "@app/shared/components/dialogs/food-nutrient-filter-dialog/food-nutrient-filter.enum";
import { createAction, props } from "@ngrx/store";

export const loadNutrientFilters = createAction(
  "[Nutrient Filter / API] Load Nutrient Filters",
  props<{ modalName: FoodNutrientFilterDialogType }>(),
);
export const loadNutrientFiltersSuccess = createAction(
  "[Nutrient Filter / API] Load Nutrient Filters Success",
  props<{
    nutrientFilters: any[];
    lastFiltersType: FoodNutrientFilterDialogType;
  }>(),
);
export const loadNutrientFiltersFailure = createAction(
  "[Nutrient Filter / API] Load Nutrient Filters Failure",
  props<{ error: any }>(),
);

export const updateNutrientFilter = createAction(
  "[Nutrient Filter] Update Nutrient Filter",
  props<{ nutrientCode: string }>(),
);

export const applyNutrientFilters = createAction(
  "[Nutrient Filter / API] Apply Nutrient Filters",
  props<{ modalName: FoodNutrientFilterDialogType }>(),
);
export const applyNutrientFiltersProducts = createAction(
  "[Nutrient Filter / API] Apply Nutrient Filters Products",
);
export const applyNutrientFiltersRation = createAction(
  "[Nutrient Filter / API] Apply Nutrient Filters Ration",
);
export const applyNutrientFiltersDietarySupplement = createAction(
  "[Nutrient Filter / API] Apply Nutrient Filters Dietary Supplement",
);
// Ration
export const applyNutrientFiltersRationDietarySupplement = createAction(
  "[Nutrient Filter / API] Apply Nutrient Filters Ration Dietary Supplement",
);
export const applyNutrientFiltersRationProducts = createAction(
  "[Nutrient Filter / API] Apply Nutrient Filters Ration Products",
);
export const applyNutrientFiltersRationRecipe = createAction(
  "[Nutrient Filter / API] Apply Nutrient Filters Ration Recipe",
);
//
export const applyNutrientFiltersRecipeCreate = createAction(
  "[Category Filter / API] Apply Nutrient Filters Recipe Create",
);

export const applyNutrientFiltersRecipe = createAction(
  "[Category Filter / API] Apply Nutrient Filters Recipe",
);

export const resetAppliedNutrientFilters = createAction(
  "[Nutrient Filter] Reset Applied Nutrient Filters",
);

export const resetNutrientFilters = createAction(
  "[Nutrient Filter] Reset Nutrient Filters",
);

export const restoreAppliedNutrientFilters = createAction(
  "[Nutrient Filter] Restore Applied Nutrient Filters",
);

export const clearNutrientFilters = createAction(
  "[Nutrient Filter] Clear Nutrient Filters",
);
